.center-div {
  display: flex;
  width: 100%;
  height: 400px;
}

.about-div {
  width: 50%;
  background: black;
  color: white;
  text-align: center;
  border: 1px solid white;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  padding: 10px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-div{
  background: url('../../images/povijest.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.right-div{
  background: url('../../images/vodstvo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.background-image {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
}

.background-image img{
  width: inherit;

}

.main-page-about {
  margin: 10px 18%;
}

.about-title {
  font-size: 24px;
  margin: 20px;
}

.about-content{
  position: relative;
  text-shadow: 2px 1px black;
  font-size: 20px;
  margin: 20px;
}

.about-div:hover {
  cursor: pointer;
  -webkit-filter: unset; /* Safari 6.0 - 9.0 */
  filter: unset;
  transform: scale(1.04);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.about-text {
  max-height: fit-content;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
}

//phones and tablets
@media (min-width: 320px) and (max-width: 1024px) {
  .about-div {
    -webkit-filter: unset;
    filter: unset;
    width: 100%;
  }

  .center-div {
    display: block;
    width: 100%;
  }

  .main-page-about {
    margin: 10px;
  }

  .about-text{
    font-size: 24px;
  }

  .about-title{
    font-size: 35px;
  }
}
