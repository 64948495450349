.content-title {
  font-weight: 600;
  margin: 20px 0px;
}

.content-text {
  margin: 0px 10px 0px 40px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

@media (max-width: 1024px) {
  .content-title {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    color: whitesmoke;
    text-shadow: 2px 1px black;
    font-weight: 300;
  }

  .content-text {
    display: none;
  }
}
