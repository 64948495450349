.list-element {
  display: flex;
}

.content-wrapper {
  margin: 5px;
  width: 100%;
  position: relative;
}

.more-button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.list-button {
  background: #5c2516;
  color: white;
  height: 23px;
  width: 100px;
  border: 1px solid #5c2516;
  border-radius: 3px;
}

.list-button:hover {
  cursor: pointer;
}

.element-date {
  position: absolute;
  right: 0;
  top: 0;
  color: gray;
}

.list-title {
  margin-top: 25px;
  color: #5c2516;
  max-width: 60%;
}

.list-title a {
  color: #5c2516;
}


.list-content {
  max-height: fit-content;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  color: #4f4c4c;
  margin-bottom: 30px;
}

.list-picture {
  align-items: center;
  justify-content: center;
  display: flex;
}

.list-picture img {
  width: 100px;
}

@media only screen and (max-width: 450px) {
  .list-picture img {
    width: 120px;
  }

}
