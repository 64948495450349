.live-news-preview {
  background-image: url('../../images/hkc-example.jpg');
  max-height: 300px;
}

.title {
  width: 100%;
  height: 40px;
  background-color: #5c2516;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.news-wrapper {
  width: 100%;
}

.title-text {
  margin-left: 10px;
}

.list-elements-wrapper {
  margin: 10px;
}

.list-element-wrapper {
  padding: 5px;
  border-bottom: 0.5px solid gray;
}

@media only screen and (max-width: 1105px) {
  .first-static-image img,
  .second-static-image img {
    display: none;
  }
}

.first-static-image,
.second-static-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
