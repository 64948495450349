.tile-element {
  display: inline-block;
  width: 50%;
  font-size: 24px;
  vertical-align: middle;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 20;
  overflow: hidden;
  margin-bottom: -5px;
}



@media (max-width: 1024px) {
  .tile-element {
    width: 100%;
    display: block;
  }
}
