.images-container img {
  width: auto;
  height: 300px;
  margin: 5px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.images-container {
  display: block;
  margin-left: 40px;
  margin-right: 40px;
}

.dep-container {
  text-align: center;
  margin-left: 100px;
  margin-right: 100px;
}

@media (max-width: 700px) {
  .dep-container {
    margin: 10px 30px;
  }
}

@media (max-width: 850px) {
  .dep-container {
    justify-content: center;
    display: grid;
  }
  .images-container img {
    height: auto;
    width: 100%;
    margin-left: 0px;
  }
}

.dep-title {
  font-size: 25px;
  color: #5c2516;
}

.dep-content {
  white-space: pre-line;
  text-align: justify;
  color: #4f4c4c;
}
