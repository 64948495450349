.input-field {
    height: 56px;
    font-size: 16px;
    border-radius: 4px;
    padding: 16px;
    padding-right: 36px;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 4px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
}

.contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
}

.contact-form {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-info {
    width: 50%;
    border: 1.5px solid;
    padding-left: 50px;
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    border-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(92, 37, 22, 1) 48%,
        rgba(255, 255, 255, 0) 100%
    );
    border-image-slice: 1;
    color: gray;
    display: grid;
}

@media only screen and (max-width: 710px) {
    .contact-container {
        display: block;
    }

    .contact-form {
        width: 100%;
    }

    .contact-info {
        width: 80%;
        display: block;
        border: none;
        border: 1.5px solid;
        margin-top: 50px;
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
        border-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(92, 37, 22, 1) 48%,
            rgba(255, 255, 255, 0) 100%
        );
        border-image-slice: 1;
    }

    .contact-footer {
        position: static;
    }
}

.contact-title {
    font-weight: 500;
    color: rgba(92, 37, 22, 1);
    font-size: 20px;
    margin-left: 10px;
}

.form-holder {
    width: 60%;
}

input[type="text"],
input[type="email"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 16px;
}

.contact-info-icons {
    display: flex;
    margin: 5px;
    word-break: break-all;
}

.contact-icon {
    display: inline;
    margin: 5px;
}

.colored-icons {
    color: rgba(92, 37, 22, 1);
}

.submit-button {
    width: 100%;
    height: 40px;
    background: rgba(92, 37, 22, 1);
    color: white;
    border-radius: 3px;
    border: 1px solid rgba(92, 37, 22, 1);
    font-size: 18px;
}

.contact-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

@media only screen and (max-height: 700px) {
    .contact-footer {
        position: static;
    }
}
