.MuiTabs-flexContainer {
    display: flex;
    justify-content: center;
}

.MuiAppBar-colorDefault {
    background-color: transparent !important;
    box-shadow: none !important;
}

.video{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app-div-video .MuiPagination-ul{
    margin: 10px !important;
}