.images-container img {
  width: auto;
  height: 300px;
  margin: 0px !important
}

.images-container {
  display: block;
  margin: 0px;
}

@media (max-width: 850px) {
  .images-container img {
    height: auto;
    width: 100%;
    margin-left: 0px;
  }
}


.images-container {
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  display: inline-block;
  text-align: center;
}