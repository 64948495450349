.album-cover img {
  width: 100%
}

.album-cover {
  position: relative;
  display: inline-block;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}

.album-content{
  margin: 5px;
  font-size: 18px;
  font-weight: 300;
}