.department-wrapper {
  display: inline-block;
  width: 100%;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

@media (max-width: 1024px) {
  .department-wrapper {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
  }
}

.department-wrapper:hover {
  cursor: pointer;
  -webkit-filter: unset; /* Safari 6.0 - 9.0 */
  filter: unset;
  transform: scale(1.04);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.images-container:hover {
  cursor: pointer;
}