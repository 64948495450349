.transparent-tile {
  width: 35%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.transparent-tile text {
  opacity: 0 !important;
}

h3,
.slider-text {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
}

.slider-text {
  font-size: 21px;
}

.img-text {
  background-repeat: no-repeat;
  background-size: cover;
}
