.album-dialog img {
    max-height: 80vh;
    max-width: 100vh;
}

.album-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-button:hover {
    cursor: pointer;
}

.buttons-album-image:hover {
    cursor: pointer;
    color: #4b1c0f;
}