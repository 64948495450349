.title-content {
    width: 600px;
}
.videos-content {
    margin-top: 40px;
    white-space:pre-line;
    text-align: justify;
    color: #4f4c4c;
}
.videos-title {
    color: #5c2516;
    font-size: 24px;
    margin-left: 10px;
}
.video-player {
    width: 50%;
    float: inline-end;
    margin-left: 40px;
    margin-bottom: 10px;
}

.videos-container {
    display: inline-flex;
    border-bottom: 1px solid #5c2516;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1160px) {
    .videos-container {
        display: block;
        width: 100%;
    }
    .title-title {
        font-size: 10px;
    }

    .title-content{
        width: initial;
        margin: 10px;
        text-align: center;
    }

    .video-player{
        display: flex;
        justify-content: center;
        margin: 10px;
        width: initial
    }
}