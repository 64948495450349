.header {
  width: 100%;
}
.nav-bar {
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 80px;
}

.nav-elem {
  padding: 0px 15px;
  color: #5c2516;
}

.nav-border {
  border-right: 1px solid #5c2516;
}

.links-wrapper {
  margin-top: 30px;
  margin-right: 50px;
}

a {
  font-size: 21px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #9b5542;
  opacity: 0.7;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
}

a.selected-link {
  color: #9b5542;
}

.no-fading:hover{
  opacity: 1;
}

.menu-button {
  display: none;
}

@media only screen and (max-width: 955px) {
  .links-wrapper a {
    font-size: 10px;
    cursor: pointer;
    text-decoration: none;
  }

  div.links-wrapper {
    display: none;
  }

  div.menu-button {
    display: unset;
    color:#5c2516;
    margin: 15px;
  }

}

.MuiListItem-button{
  color:#5c2516 !important;
}

.MuiIconButton-root{
  color: #5c2516 !important;
}
