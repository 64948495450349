.each-slide {
  height: 500px;
  width: 100%;
}

.img-text {
  height: 100%;
  width: 100%;
}

.input-icon {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 15px;
  color: gray;
}

.contact-fields {
  position: relative;
}

.center-div {
}

.live-news-preview {
  background-image: url('../../images/hkc-example.jpg');
  max-height: 300px;
}

.title {
  width: 100%;
  height: 7%;
  background-color: #5c2516;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.title-text {
  margin-left: 10px;
}

.list-elements-wrapper {
  margin: 10px;
}

.list-element-wrapper {
  padding: 5px;
  border-bottom: 0.5px solid gray;
}

@media (max-width: 850px) {
  .slide-container {
    display: none;
  }
}
