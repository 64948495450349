.dialog-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
}

.dialog-content {
  height: 80%;
  position: absolute;
}

@media (max-width: 700px) {
  .dialog-content {
    max-height: 80%;
    max-width: 80%;
    height: unset;
  }
  .dialog-content img {
    width: 100%;
    height: 100%;
  }
}

.dialog-close-icon {
  color: whitesmoke;
  cursor: pointer;
}

.dialog-back-button {
  position: absolute;
  right: -10px;
  top: -10px;
}
