.footer {
  background-color: #5c2516;
  opacity: 0.8;
  color: white;
  width: 100%;
  height: 200px;
  text-align: center;
}

@media only screen and (max-width: 854px) {
.footer{
  height: 100px;
}
}

.footer a {
  color: white;
  text-decoration: none;
  margin: 5px;
  font-size: 15px;
}

.icons {
  padding-top: 15px;
}

.icon {
  margin: 10px;
}
.links-wrapper {
  margin-bottom:30px
}
