span {
  color: #4f4c4c;
}

h4 {
  color: #5c2516;
}

.back-button .MuiSvgIcon-root {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.back-button {
  font-size: 18px;
  margin: 10px 30px;
  display: flex;
  align-items: center;
  color: #5c2516;
}

.text-uo {
  text-align: center;
}
