.images-wrapper {
 // padding: 10px;
  margin: 10px !important;
 // border: 1px solid black;
  border-radius: 1em;
  display: inline-block;
  filter: drop-shadow(8px 8px 10px black);
}

.images-title{
    text-align: center;
    font-size: 25px;
    font-weight: 300;
    width: 100%;
    padding: 10px;
    color: #5c2516;
}

.images-wrapper:hover {
  cursor: pointer;
}